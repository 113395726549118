import { useQuery } from "@tanstack/react-query";
import Api from "../services/Api";
import { Invoice } from "./useInvoices";

export type Response = {
  has_more: boolean;
  has_prev: boolean;
  item_count: number;
  page: number;
  page_count: number;
  invoices: Invoice[];
};

const useAllInvoices = (props: {
  accountId?: string | null;
  limit?: number;
  dateFrom?: string;
  dateTo?: string;
  page?: number;
}) => {
  const { accountId, dateFrom, dateTo, limit = 10, page = 1 } = props;
  const fetchData = Api.getAllInvoices;
  const { isLoading, isInitialLoading, isError, data, error, refetch, status, isFetching } =
    useQuery(
      ["invoices", page, limit, dateFrom],
      () => {
        return fetchData({
          page,
          limit,
          accountId,
          dateFrom,
          dateTo
        }).then((res: Response) => {
          return res;
        });
      },
      {
        keepPreviousData: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        cacheTime: 0
      }
    );

  return {
    data,
    error,
    status,
    isLoading,
    isInitialLoading,
    isError,
    refetch,
    isFetching
  };
};

export default useAllInvoices;
