import { useQuery } from "@tanstack/react-query";
import Api from "../services/Api";

export enum InvoiceType {
  paid = "paid",
  preview = "preview"
}

export type Invoice = {
  accountId: string;
  name: string;
  type: InvoiceType;
  userId: string;
  profileType?: string;
  claimNumber: number | null;
  invoiceDate?: string;
  invoiceId: string | number;
  invoiceNo: string | number;
  bountyId: number;
  bountyTitle?: string | null;
  bountyAmount: number;
  currency: string;
};

export type Response = {
  has_more: boolean;
  has_prev: boolean;
  item_count: number;
  page: number;
  page_count: number;
  invoices: Invoice[];
};

const useInvoices = (props: {
  bountyId: string | number;
  accountId: string;
  dateFrom?: string;
  dateTo?: string;
  page?: number;
  limit?: number;
}) => {
  const { bountyId, accountId, dateFrom, dateTo, page, limit } = props;

  const fetchData = Api.getInvoices;
  const { data, error, isInitialLoading, isError, isFetching, status, refetch } = useQuery(
    ["items", bountyId, accountId, page, limit, dateFrom],
    () => {
      return fetchData({
        bountyId,
        accountId,
        dateFrom,
        dateTo,
        page,
        limit
      }).then((res: Response) => {
        return res;
      });
    },
    {}
  );

  return {
    data,
    error,
    status,
    isInitialLoading,
    isError,
    isFetching,
    refetch
  };
};

export default useInvoices;
