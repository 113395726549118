import useInvoices from "../../../hooks/useInvoices";
import { Bounty } from "../../../types/Bounty";
import { Area } from "../../Layout";
import DownloadInvoiceAction from "./general/Actions/DownloadInvoiceAction";
import PreviewInvoiceAction from "./general/Actions/PreviewInvoiceAction";
import { getInvoiceDate } from "./general/helpers";
import { Table, TableBody, TableDataCell, TableHeaderCell, TableRow } from "./general/Table";
import useWalletAccountId from "../../../hooks/useWalletAccountId";
import ExportCSVFile from "./general/Actions/ExportCSVFile";
import { useState } from "react";
import TableSummary from "./general/Table/TableSummary";
import DownloadSelectedInvoicesAction from "./general/Actions/DownloadSelectedInvoicesAction";
import CheckBox from "../../general/CheckBox";
import DropdownMenu from "../../general/DropdownMenu";
import { SelectArrow } from "../../general/SVGIcon";
import Separator from "../../general/Separator";
import { NavLink } from "react-router-dom";
import moment from "moment";
import Pagination from "./general/Pagination";

const BountyInvoices = (props: { bounty: Bounty }) => {
  const PAGE_SIZE = 10;
  const { bounty } = props;
  const { walletAccountId: accountId } = useWalletAccountId();
  const [currentPage, setCurrentPage] = useState(1);
  const [period, setPeriod] = useState<"year" | "currMonth" | "lastMonth" | "allPeriod">("year");
  const [ids, setIds] = useState<Array<string | number> | []>([]);
  const [loading, setLoading] = useState(false);

  const queryParams = {
    bountyId: bounty.id,
    accountId,
    dateFrom:
      period === "year"
        ? moment().startOf("year").toISOString()
        : period === "currMonth"
        ? moment().startOf("month").toISOString()
        : period === "lastMonth"
        ? moment(moment().subtract(1, "month").toISOString().substring(0, 13)).toISOString()
        : undefined,
    dateTo: moment().toISOString(),
    limit: -1
  };

  const { data, isInitialLoading } = useInvoices(queryParams);

  const currentTableData = () => {
    const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
    const lastPageIndex = firstPageIndex + PAGE_SIZE;
    return data?.invoices.slice(firstPageIndex, lastPageIndex) ?? [];
  };

  const handleSetPeriod = (period: "year" | "currMonth" | "lastMonth" | "allPeriod") => {
    setPeriod(period);
  };

  const handleSetLoading = (value: boolean) => {
    setLoading(value);
  };

  if (isInitialLoading) {
    return (
      <div className="flex items-center justify-center">
        <span className="dot-pulse" />
      </div>
    );
  }

  const handleCheckAllInvoices = () => {
    const selected = [...(data?.invoices ?? [])].map((invoice) => invoice.invoiceNo);
    if (selected.length === [...ids].length) {
      setIds([]);
    } else {
      setIds(selected);
    }
  };

  const handleUnCheckAllInvoices = () => {
    setIds([]);
  };

  const Summary = () => {
    return (
      <TableSummary>
        <div className="font-poppins flex justify-between">
          <div className="flex flex-[50%] justify-start gap-4">
            <DownloadSelectedInvoicesAction
              disabled={ids.every((item) => item === undefined)}
              ids={ids}
              accountId={accountId}
              loading={loading}
              handleSetLoading={handleSetLoading}
              onFinish={handleUnCheckAllInvoices}
            />
            <ExportCSVFile
              disabled={ids.every((item) => item === undefined)}
              ids={ids}
              accountId={accountId}
            />
          </div>
          <div className="flex flex-[50%] justify-between">
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={data?.item_count ?? 0}
              pageSize={PAGE_SIZE}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      </TableSummary>
    );
  };

  const handleCheckInvoice = (id: string | number) => {
    const selected = [...ids].includes(id) ? [...ids].filter((item) => item !== id) : [...ids, id];
    setIds(selected);
  };

  return data ? (
    <div className="w-full py-4">
      <Table tableSummary={<Summary />}>
        <thead>
          <TableRow>
            <TableHeaderCell>
              <div className="flex items-center relative">
                <CheckBox
                  {...{
                    disabled: data.invoices.length === 0,
                    onClick: () => handleCheckAllInvoices(),
                    active: Boolean(ids.length && ids.length === data.invoices.length)
                  }}
                />
                <DropdownMenu
                  {...{
                    customStyles: {
                      content: "flex",
                      dropdownMenu: "left-0 bg-neutral-900 rounded-2xl max-w-[180px] px-0 py-2"
                    }
                  }}
                  icon={
                    <SelectArrow className="stroke-neutral-500 hover:stroke-primary-900 w-4 h-4" />
                  }>
                  <div className="p-2.5">
                    <CheckBox
                      {...{
                        customStyles: { text: "text-xs normal-case" },
                        text: "Select All",
                        active: Boolean(ids.length && ids.length === data.invoices.length),
                        onClick: () => handleCheckAllInvoices()
                      }}
                    />
                  </div>
                  <Separator />
                  <div className="p-2.5">
                    <CheckBox
                      {...{
                        customStyles: { text: "text-xs normal-case" },
                        text: "Unselect All",
                        active: ids.length === 0,
                        onClick: () => handleUnCheckAllInvoices()
                      }}
                    />
                  </div>
                  <Separator />
                  <div className="p-2.5">
                    <CheckBox
                      {...{
                        customStyles: { text: "text-xs normal-case" },
                        text: "Month to date",
                        active: period === "currMonth",
                        onClick: () => handleSetPeriod("currMonth")
                      }}
                    />
                  </div>
                  <Separator />
                  <div className="p-2.5">
                    <CheckBox
                      {...{
                        customStyles: { text: "text-xs normal-case" },
                        text: "Last month",
                        active: period === "lastMonth",
                        onClick: () => handleSetPeriod("lastMonth")
                      }}
                    />
                  </div>
                  <Separator />
                  <div className="p-2.5">
                    <CheckBox
                      {...{
                        customStyles: { text: "text-xs normal-case" },
                        text: "Year to date",
                        active: period === "year",
                        onClick: () => handleSetPeriod("year")
                      }}
                    />
                  </div>
                  <Separator />
                  <div className="p-2.5">
                    <CheckBox
                      {...{
                        customStyles: { text: "text-xs normal-case" },
                        text: "All invoices",
                        active: period === "allPeriod",
                        onClick: () => handleSetPeriod("allPeriod")
                      }}
                    />
                  </div>
                </DropdownMenu>
              </div>
            </TableHeaderCell>
            <TableHeaderCell>Date</TableHeaderCell>
            <TableHeaderCell>Recipient</TableHeaderCell>
            <TableHeaderCell>Amount</TableHeaderCell>
            <TableHeaderCell className="text-center">Actions</TableHeaderCell>
          </TableRow>
        </thead>
        <TableBody>
          {currentTableData().length > 0 ? (
            currentTableData().map((invoice) => (
              <TableRow key={invoice.invoiceId}>
                <TableDataCell>
                  <CheckBox
                    {...{
                      active: Boolean(ids.find((item) => item === invoice.invoiceNo)),
                      onClick: () => handleCheckInvoice(invoice.invoiceNo)
                    }}
                  />
                </TableDataCell>
                <TableDataCell>{getInvoiceDate(invoice.invoiceDate)}</TableDataCell>
                <TableDataCell>
                  {invoice.name ? (
                    <NavLink to={`/user-info/${invoice.name}?type=${invoice.profileType}`}>
                      <p className="line-clamp-1">{invoice.name}</p>
                    </NavLink>
                  ) : (
                    <p className="">{invoice.accountId}</p>
                  )}
                </TableDataCell>
                <TableDataCell>
                  {invoice.bountyAmount} {invoice.currency}
                </TableDataCell>
                <TableDataCell>
                  <div className="flex gap-2 items-center justify-center">
                    <DownloadInvoiceAction invoice={invoice} accountId={accountId} />
                    <PreviewInvoiceAction invoice={invoice} accountId={accountId} />
                  </div>
                </TableDataCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableDataCell colSpan={5}>
                <div className="p-2 text-center text-neutral-50">Invoices not found.</div>
              </TableDataCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  ) : (
    <div className="mt-4">
      <Area
        {...{
          customStyles: {
            borderContainer: "border border-neutral-700",
            contentContainer: "bg-neutral-800"
          }
        }}>
        <div className="p-8 text-center text-neutral-50">Invoices not found.</div>
      </Area>
    </div>
  );
};

export default BountyInvoices;
